import sharedTableActions from "@/mixins/shared-table-actions.mixin";
import _ from "lodash";

export default {
  mixins: [sharedTableActions],
  methods: {
    getDefaultListOptions() {
      return {
        order_by: "name",
        order_direction: "ASC",
        search_text: null,
        created_at: [null, null],
        hr: "yes",
        w9: [
          { name: "OUT_FOR_SIGNATURE", label: "Sent", value: true },
          { name: "SIGNED", label: "Signed", value: true },
          { name: "NONE", label: "None", value: true },
        ],
        ach: [
          { name: "OUT_FOR_SIGNATURE", label: "Sent", value: true },
          { name: "SIGNED", label: "Signed", value: true },
          { name: "NONE", label: "None", value: true },
        ],
        msa: [
          { name: "OUT_FOR_SIGNATURE", label: "Sent", value: true },
          { name: "SIGNED", label: "Signed", value: true },
          { name: "NONE", label: "None", value: true },
        ],
        confirmed: "all",
        qb_is_linked: "all",
      };
    },
    resetListOptions() {
      this.listOptions = this.getDefaultListOptions();
      this.searchInput = null;
      this.fetchList();
      this.reloadKey++;
    },
    resetFilter(fieldName) {
      switch (fieldName) {
        case "hr":
          this.listOptions[fieldName] = "yes";
          break;
        case "w9":
        case "ach":
        case "msa":
          this.listOptions[fieldName] = this.getDefaultListOptions()[fieldName];
          break;
        case "confirmed":
        case "qb_is_linked":
          this.listOptions[fieldName] = "all";
          break;
        case "created_at":
          this.listOptions[fieldName] = [null, null];
          break;
        default:
          this.listOptions[fieldName] = null;
      }
      this.optionsUpdate();
    },
    activeListOption(fieldName) {
      switch (fieldName) {
        case "hr":
          return this.listOptions[fieldName] != "yes";
        case "w9":
        case "ach":
        case "msa": {
          const enabledStatusNames = this.listOptions[fieldName]
            .filter((type) => type.value)
            .map((type) => type.name);
          return !_.isEqual(enabledStatusNames, [
            "OUT_FOR_SIGNATURE",
            "SIGNED",
            "NONE",
          ]);
        }
        case "confirmed":
          return this.listOptions[fieldName] != "all";
        case "qb_is_linked":
          return this.listOptions[fieldName] != "all";
        case "created_at":
          return this.listOptions[fieldName].filter((date) => date).length > 0;
        default:
          return this.listOptions[fieldName];
      }
    },
  },
  computed: {
    listParams() {
      return {
        order_by: this.listOptions.order_by,
        order_direction: this.listOptions.order_direction,
        search_text: this.listOptions.search_text,
        hr: this.listOptions.hr,
        w9: this.listOptions.w9
          .filter((type) => type.value)
          .map((type) => type.name),
        ach: this.listOptions.ach
          .filter((type) => type.value)
          .map((type) => type.name),
        msa: this.listOptions.msa
          .filter((type) => type.value)
          .map((type) => type.name),
        confirmed: this.listOptions.confirmed,
        qb_is_linked: this.listOptions.qb_is_linked,
        created_at: this.dateParam(this.listOptions.created_at),
      };
    },
    anyActiveListOptions() {
      return (
        [
          "hr",
          "w9",
          "ach",
          "msa",
          "confirmed",
          "qb_is_linked",
          "created_at",
        ].filter((fieldName) => this.activeListOption(fieldName)).length > 0 ||
        this.listOptions.order_by != "name" ||
        this.listOptions.order_direction != "ASC"
      );
    },
  },
};
