<template>
  <div class="for-review-page" v-if="currentUser">
    <div class="page-title">For Review</div>
    <div class="page-subtitle">
      Below are active Creators, Gigs, Ideations, and Submissions for your
      review.
    </div>
    <div class="for-review__my-toggle">
      <div class="for-review__my-toggle__label">Assigned to me:</div>
      <v-switch
        inset
        hide-details
        v-model="my"
        @change="toggleMy"
        aria-label="Filter to my opportunities, or show all"
      />
    </div>
    <div v-if="creatorsForReview" class="for-review__creators">
      <CreatorsSection :preview="true" :value="creatorsForReview" light />
    </div>
    <div v-if="loaded && !loadingSubmissions">
      <SubmissionReviewGroup :value="gigGroups" title="Gigs for Review" />
      <SubmissionReviewGroup
        :value="otherGroups"
        title="Submissions for Review"
      />
    </div>
    <div class="no-entries" v-else-if="noSubmissions && !loadingSubmissions">
      No Submissions
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import IndexPage from "./index-page";
import SubmissionReviewGroup from "@/components/review/SubmissionReviewGroup.vue";

export default {
  name: "ForReviewPage",
  extends: IndexPage,
  components: { SubmissionReviewGroup },
  head: {
    title: "For Review",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      gigGroups: null,
      otherGroups: null,
      submissionDialog: false,
      submissionDialogContent: null,
      creatorsForReview: null,
      creatorsForReviewInc: 0,
      my: true,
      loadingSubmissions: false,
    };
  },
  mounted() {
    this.fetchCreatorsForReview();
  },
  methods: {
    async fetchList() {
      this.loadingSubmissions = true;
      const params = {};
      if (this.my) params.my = true;
      const { data } = await this.$api.Submission.forReview(params);

      this.gigGroups = data.gig_groups;
      this.otherGroups = data.other_groups;
      this.loadingSubmissions = false;
    },
    showSubmission(submission) {
      this.submissionDialogContent = submission;
      this.submissionDialog = true;
    },
    async fetchCreatorsForReview() {
      const { data } = await this.$api.Creator.sections({
        section_name: "for_review",
      });
      this.creatorsForReview = data[0];
    },
    toggleMy() {
      this.fetchList();
    },
  },
  computed: {
    loaded() {
      return (
        (this.gigGroups && this.gigGroups.length > 0) ||
        (this.otherGroups && this.otherGroups.length > 0)
      );
    },
    noSubmissions() {
      return (
        this.gigGroups &&
        this.gigGroups.length == 0 &&
        this.otherGroups &&
        this.otherGroups.length == 0
      );
    },
  },
};
</script>

<style scoped lang="scss">
.for-review {
  &__creators {
    margin-top: 77px;
  }
  &__my-toggle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__label {
      font-size: 14px;
      font-weight: bold;
      margin-right: 0.75em;
    }
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
</style>
