export default {
  computed: {
    themeDisabledPage() {
      return [
        "Account",
        "Referral",
        "Profile",
        "ProfileView",
        "CreatorPaymentsView",
        "Dashboard",
        "Submissions",
        "ForReview",
        "ClaimAccount",
        "Referral",
        "Submission",
        "CreateOpportunity",
        "My Gigs",
        "My Payments",
        "My Agreements",
        "Payments",
        "CreatorPaymentsViewPage",
        "Payments All Gigs",
        "Payments Dashboard",
        "Project Members",
        "Finance All Projects",
        "Edit Gig Page",
        "Show Gig Page",
        "Agreements",
        "Creator Agreements Page",
        "Monday.com Onboarding",
        "Edit Hire Request",
        "Creator Hire Requests Page",
        "Hire Requests",
        "Hire Request",
        "Show My Gig Page",
        "Gig Extension Page",
        "Show My Gig Extension Page",
        "login",
        "Payments Reports",
        "Onboarding Dashboard",
      ].includes(this.$route.name);
    },
  },
};
