<template>
  <div class="finance-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="page-title">Onboarding Dashboard</div>
    <div :key="`reload-key-${reloadKey}`">
      <OnboardingTable
        :key="`onboarding-section-${reloadKey}`"
        :items="list"
        @refresh="reloadKey++"
        :class="{ 'table-loading': loadingList }"
        :listOptions="listOptions"
        @orderUpdate="orderUpdate($event)"
        @filterUpdate="filterUpdate($event)"
      >
        <template #actions>
          <div class="payments-table__action">
            <div class="payments-table__action__label">Filter by:</div>
            <v-overlay
              :value="filtersMenu"
              @click.native.stop="closeFilters()"
            />
            <v-menu
              bottom
              offset-y
              :close-on-click="false"
              :close-on-content-click="false"
              v-model="filtersMenu"
              light
              class="filters-menu"
              content-class="filters-menu__content"
            >
              <template v-slot:activator="{ on }">
                <TableActiveFilters
                  v-on="on"
                  @openMenu="filtersMenu = true"
                  :listOptions="listOptions"
                  :fields="filterFields"
                  @resetFilter="resetFilter($event)"
                  :activeListOption="activeListOption"
                />
              </template>
              <v-card class="filters-menu__content__card">
                <div class="filters-menu__body">
                  <v-expansion-panels v-model="filterPanels" multiple flat>
                    <TableRadioFilterPanel
                      :listOptions="listOptions"
                      fieldName="hr"
                      @filterUpdate="filterMenuUpdate($event)"
                      fieldLabel="Hire Request(s)"
                      reset
                      :defaultListOptions="getDefaultListOptions()"
                      :items="yesNoAllItems"
                    />
                    <TableOptionsFilterPanel
                      :listOptions="listOptions"
                      fieldName="w9"
                      @filterUpdate="filterMenuUpdate($event)"
                      fieldLabel="W-9 Status"
                      selectAll
                      reset
                      :defaultListOptions="getDefaultListOptions()"
                    />
                    <TableOptionsFilterPanel
                      :listOptions="listOptions"
                      fieldName="ach"
                      @filterUpdate="filterMenuUpdate($event)"
                      fieldLabel="ACH Status"
                      selectAll
                      reset
                      :defaultListOptions="getDefaultListOptions()"
                    />
                    <TableOptionsFilterPanel
                      :listOptions="listOptions"
                      fieldName="msa"
                      @filterUpdate="filterMenuUpdate($event)"
                      fieldLabel="MSA Status"
                      selectAll
                      reset
                      :defaultListOptions="getDefaultListOptions()"
                    />
                    <TableRadioFilterPanel
                      :listOptions="listOptions"
                      fieldName="confirmed"
                      @filterUpdate="filterMenuUpdate($event)"
                      fieldLabel="Account Claimed"
                      reset
                      :defaultListOptions="getDefaultListOptions()"
                      :items="yesNoAllItems"
                    />
                    <TableRadioFilterPanel
                      :listOptions="listOptions"
                      fieldName="qb_is_linked"
                      @filterUpdate="filterMenuUpdate($event)"
                      fieldLabel="Quickbooks Vendor"
                      reset
                      :defaultListOptions="getDefaultListOptions()"
                      :items="yesNoAllItems"
                    />
                    <TableDateFilterPanel
                      :listOptions="listOptions"
                      fieldName="created_at"
                      @filterUpdate="filterMenuUpdate($event)"
                      fieldLabel="Date Created"
                    />
                  </v-expansion-panels>
                </div>
              </v-card>
            </v-menu>
          </div>
          <div class="payments-table__action">
            <div class="payments-table__action__label"></div>
            <TextField v-model="searchInput" placeholder="Search" />
          </div>
          <div class="payments-table__secondary-actions">
            <div
              class="
                payments-table__action
                payments-table__action--compact
                payments-table__action--download
              "
            >
              <ActionsMenu
                @close="downloadMenu = false"
                @toggle="downloadMenu = !downloadMenu"
                :menu="downloadMenu"
                themeDisabledPage
                icon="$download"
                :iconSize="24"
              >
                <form
                  method="post"
                  action="/api/v1/profile_side/creators/onboarding_csv"
                  target="_blank"
                  style="margin-bottom: 21px"
                >
                  <input
                    type="hidden"
                    name="token"
                    :value="$store.getters['auth/token']"
                  />
                  <input type="hidden" name="all" value="true" />
                  <input
                    type="hidden"
                    v-for="item in Object.entries(listParams)"
                    :key="`listParams-${item[0]}`"
                    :name="item[0]"
                    :value="item[1]"
                  />
                  <v-btn
                    type="submit"
                    color="#56565A"
                    elevation="0"
                    style="color: white !important"
                    >Export CSV</v-btn
                  >
                </form>
              </ActionsMenu>
            </div>
            <div
              class="
                payments-table__action-btn payments-table__action-btn--reset
              "
              v-if="anyActiveListOptions"
            >
              <div @click="resetListOptions()">
                <v-icon size="14">$close</v-icon> Reset
              </div>
            </div>
          </div>
        </template>
        <template #pagination>
          <TablePagination
            :page="page"
            :totalPages="totalPages"
            @input="page = $event"
            :key="`pagination-${reloadKey}`"
          />
        </template>
      </OnboardingTable>
    </div>
  </div>
</template>

<script>
import OnboardingTable from "./onboarding-dashboard/table.onboarding-dashboard";
import onboardingTableActions from "@/mixins/onboarding-table-actions.mixin";
import TableOptionsFilterPanel from "@/components/tables/TableOptionsFilterPanel";
import TableRadioFilterPanel from "@/components/tables/TableRadioFilterPanel";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";

export default {
  head() {
    return {
      title: "Onboarding Dashboard",
      titleTemplate: "%s | iU Community",
    };
  },
  mixins: [onboardingTableActions, paginatedTable],
  components: {
    OnboardingTable,
    TableOptionsFilterPanel,
    TableRadioFilterPanel,
    TableDateFilterPanel,
    TableActiveFilters,
    TablePagination,
  },
  data() {
    return {
      list: [],
      loadingList: false,
      listOptions: this.getDefaultListOptions(false),
      downloadMenu: false,
      filterFields: [
        { name: "hr", label: "Hire Request(s)" },
        { name: "w9", label: "W-9 Status" },
        { name: "ach", label: "ACH Status" },
        { name: "msa", label: "MSA Status" },
        { name: "confirmed", label: "Account Claimed" },
        { name: "created_at", label: "Date Created" },
        { name: "qb_is_linked", label: "QB Vendor" },
      ],
      reloadKey: 1,
      yesNoAllItems: [
        {
          title: "Yes",
          value: "yes",
        },
        {
          title: "No",
          value: "no",
        },
        {
          title: "All",
          value: "all",
        },
      ],
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    getParams() {
      const params = { ...this.listParams };
      params.page = this.page;
      params.per = 10;
      return params;
    },
    async fetchList() {
      this.loadingList = true;
      const params = this.getParams();
      const { data } = await this.$api.Creator.onboardingList(params);
      this.list = data.creators;
      this.totalPages = data.total_pages;
      this.loadingList = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-section.scss";
@import "@/styles/table-filters-menu.scss";
</style>
