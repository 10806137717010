<template>
  <div class="payments-table-wrapper">
    <div class="payments-table__header">
      <div class="payments-table__actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="payments-table-container">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th>
              <div>Creator</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="name"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Creator"
                isDate
              />
            </th>
            <th>
              <div>Email</div>
            </th>
            <th class="w0">
              <div>Has Hire <br />Request(s)</div>
            </th>
            <th class="w0">
              <div>W-9</div>
            </th>
            <th class="w0">
              <div>ACH</div>
            </th>
            <th class="w0">
              <div>MSA</div>
            </th>
            <th class="w0">
              <div>Account <br />Claimed</div>
            </th>
            <th class="w0">
              <div>Quickbooks <br />Vendor</div>
            </th>
            <th class="w0">
              <div>Date <br />Created</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="created_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Created"
                isDate
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <OnboardingTableRow
            v-for="item in items"
            :key="item.id"
            :item="item"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9" class="center-align">No results</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
import OnboardingTableRow from "./table-row.onboarding-dashboard.vue";
import TableHeaderActions from "@/components/tables/TableHeaderActions";

export default {
  components: {
    OnboardingTableRow,
    TableHeaderActions,
  },
  props: {
    items: Array,
    listOptions: Object,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";

.payments-table {
  &-head {
    background: #7b61ff;
    color: white;
  }
}
</style>
